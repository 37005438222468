<template>
  <div class="Top w-1920">
    <div class="top w-1200">
      <div class="left">
        <div class="menu mm">
          <img class="img1" src="../assets/home/home.png" />
          <router-link :to="{ name: 'Home' }" tag="div" class="mm">{{ $t('common.home') }}</router-link>
        </div>
        <div class="menu">
          <img src="../assets/login/jiange.gif" />
        </div>
        <div class="menu3" v-if="user">
          <div class="user" @click="yonghu">
            {{ $t('header.Account') }}:&nbsp;
            <span>{{ username }}</span>
          </div>
          <div class="out" @click="out">{{ $t('header.SignOut') }}</div>
        </div>
        <div class="logo" v-else>
          <router-link :to="{ name: 'Login' }" tag="div" class="menu mm">{{ $t('header.SignIn') }}</router-link>
          <div class="menu">
            <img src="../assets/login/jiange.gif" />
          </div>
          <router-link :to="{ name: 'Register' }" tag="div" class="menu menu_red mm">{{ $t('header.SignUp') }}</router-link>
        </div>
      </div>
      <div class="right">
        <div class="menu mm" @click="dingdan">{{ $t('header.Orders') }}</div>
        <div class="menu">
          <img src="../assets/login/jiange.gif" />
        </div>
        <!-- <div class="menu mm" @click="jifen">
          积分商城
        </div>
        <div class="menu">
          <img src="../assets/login/jiange.gif" />
        </div> -->
        <!-- <router-link class="menu" :to="{ name: 'user-centered' }" tag="div">
          我的德洋
          <img class="img1" src="../assets/home/xiajiantou.png" />
        </router-link>
        <div class="menu">
          <img src="../assets/login/jiange.gif" />
        </div> -->
        <div class="menu mm" @click="ruzhu">
          <div class="mm">{{ $t('header.Merchant_Settlement') }}</div>
          <!-- <img class="img1" src="../assets/home/xiajiantou.png" /> -->
        </div>
        <div class="menu">
          <img src="../assets/login/jiange.gif" />
        </div>
        <locales class="menu mm" :followText="true">
          <template slot="after">
            <div class="menu">
              <img src="../assets/login/jiange.gif" />
            </div>
          </template>
        </locales>

        <div class="menu mm" @click="help">
          <!-- <img class="img2" src="../assets/home/kefu.png" /> -->
          <div class="mm">{{ $t('header.Help_Center') }}</div>
          <!--          <img class="img1" src="../assets/home/xiajiantou.png" />-->
        </div>
        <!-- <div class="menu">
          <img src="../assets/login/jiange.gif" />
        </div> -->
        <!-- <div class="menu">
          <div>手机德洋</div>
          <img class="img1" src="../assets/home/shouji.png" />
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { setCookie, getCookie, delCookie } from '../utils/cookie'
import { post, get } from '@/utils/request'
export default {
  name: 'Header',
  props: ['msg'],
  data() {
    return {
      username: '',
      companyName: '',
      user: false
    }
  },
  created() {
    this.getusername()
  },
  methods: {
    getusername() {
      this.username = getCookie('username')
      if (getCookie('username')) {
        this.getAccountIsClockin()
        this.user = true
        this.getcompanyName()
      }
    },
    getAccountIsClockin() {
      get('api/account/getAccountIsClockin').then((res) => {})
    },
    getcompanyName() {
      console.log(getCookie('companyName'))
      this.companyName = getCookie('companyName')
      if (this.companyName == null || this.companyName == '' || this.companyName == 'null') {
        this.$router.push({ name: 'updatecompanyname' })
      }
    },
    jifen() {
      this.$router.push({
        name: 'Integral'
      })
    },
    out() {
      this.$confirm(this.$t('message.confirm.SignOut_Account'), this.$t('common.Confirmation'), {
        confirmButtonText: this.$t('button.Confirm'),
        cancelButtonText: this.$t('button.Cancel'),
        type: 'warning'
      }).then(() => {
        delCookie('username')
        delCookie('memberId')
        delCookie('Authorization')
        delCookie('companyName')
        this.$router.go(0)
      })
    },
    dingdan() {
      this.$router.push({
        name: 'Dingdan'
      })
    },
    yonghu() {
      this.$router.push({
        name: 'user-centered'
      })
    },
    ruzhu() {
      this.$router.push({
        name: 'ruzhu'
      })
    },
    help() {
      this.$router.tp({
        name: 'helpCenter1'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.mm {
  cursor: pointer;
}
.logo {
  display: flex;
}
.menu3 {
  margin-left: 10px;
  display: flex;
  .user {
    cursor: pointer;
  }
  span {
    color: #e19a5c;
  }
  .out {
    margin-left: 30px;
    cursor: pointer;
  }
}
.Top {
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  color: #5b5b5b;
  background: #f6f4f5;
  .top {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      .menu {
        display: flex;
        align-items: center;
        margin-left: 14px;
        .img1 {
          width: 20px;
          height: 18px;
          margin-right: 5px;
        }
      }
      .menu_red {
        color: #fe5a51;
      }
    }
    .right {
      display: flex;
      .menu {
        display: flex;
        align-items: center;
        margin-left: 14px;
        .img1 {
          margin-left: 5px;
        }
        .img2 {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
