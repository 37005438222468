import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Loading from '@/components/Loading'
import img from '@/components/use/img'
import moment from 'moment'
import locales from './utils/locales'
import date_time from './utils/date_time'

import './global.scss'
import { Message } from 'element-ui'

Vue.component('Loading', Loading)
Vue.component('Uimage', img)
Vue.use(ElementUI)

Vue.config.productionTip = false

const showMessage = Symbol('showMessage')
let messageInstance = null
class DonMessage {
  success(options, single = true) {
    this[showMessage]('success', options, single)
  }
  warning(options, single = true) {
    this[showMessage]('warning', options, single)
  }
  info(options, single = true) {
    this[showMessage]('info', options, single)
  }
  error(options, single = true) {
    this[showMessage]('error', options, single)
  }
  [showMessage](type, options, single) {
    if (messageInstance && single) {
      messageInstance.close() //先把原来的关闭
    }
    messageInstance = Message[type](options) //再创建新的消息
  }
}
Vue.prototype.$message = new DonMessage()
Vue.prototype.$moment = moment
Vue.prototype.$m = date_time
new Vue({
  i18n: locales,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
