import { getLanguage } from './locales'
import moment from 'moment'

const chinaFormat = {
  datetime: 'YYYY/MM/dd HH:mm:ss',
  date: 'YYYY/MM/DD',
  time: 'HH:mm:ss',
  yearmonth: 'YYYY/MM',
  monthday: 'MM/DD'
}
const foreignFormat = {
  datetime: 'MMM/dd/YYYY HH:mm:ss',
  date: 'MMM/dd/YYYY',
  time: 'HH:mm:ss',
  yearmonth: 'MM/YYYY',
  monthday: 'MM/DD'
}

export default new Proxy(chinaFormat, {
  get(target, key) {
    if (getLanguage() === 'en') {
      const value = foreignFormat[key]
      if (value) {
        return value
      }
    }
    return chinaFormat[key]
  }
})

moment.locale('zh', {
  relativeTime: {
    future: '%s后',
    past: '%s前',
    s: '几秒',
    ss: '%d秒',
    m: '1分钟',
    mm: '%d分钟',
    h: '1小时',
    hh: '%d小时',
    d: '1天',
    dd: '%d天',
    M: '1个月',
    MM: '%d个月',
    y: '1年',
    yy: '%d年'
  }
})

moment.locale('en', {
  relativeTime: {
    future: 'in %s', // Example: "in 5 minutes"
    past: '%s ago', // Example: "5 minutes ago"
    s: 'a few seconds',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years'
  }
})

moment.locale(getLanguage())

// 使用广播作为传递媒介防止死循环引入
const channel = new BroadcastChannel('locales')
channel.addEventListener('message', change)
function change(lang) {
  moment.locale(lang.data)
}
