<template>
  <div class="menu menu1">
    <div class="top_T">
      <p class="title">{{ $t('common.New_product_launch') }}</p>
    </div>
    <el-carousel
      trigger="click"
      indicator-position="outside"
      :autoplay="false"
      arrow="never"
    >
      <el-carousel-item v-for="(item, index) in newgood" :key="index">
        <div @click="detail(item.storeId, item.spuId)">
          <div class="tu">
            <!-- <img class="img1" src="../../assets/home/xinpin/1.png" alt="" /> -->
            <Uimage class="img2" :src="item.spuImg" alt="" />
            <!-- <img class="img3" src="../../assets/home/xinpin/2.png" alt="" /> -->
          </div>
          <div class="name1">{{ item.prodCatName }}</div>
          <div class="name2">{{ item.title }}</div>
          <div class="price">￥{{ item.salePrice }}</div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      newgood: [],
    };
  },
  created() {
    this.newgoods();
  },
  methods: {
    //新品首发
    newgoods() {
      get('f/api/frontspu?labelCode=XP&page=1&size=4').then((res) => {
        this.newgood = res.data.data.list;
      });
    },
    detail(storeId, spuId) {

       const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, '_blank');
    },
  },
};
</script>
<style lang="scss">
.menu1 {
  width: 284px;
  height: 410px;
  background: url('../../../assets/home/xinpin/back.png') no-repeat center
    center;

  .top_T {
    display: flex;
    align-items: center;
    .title {
      font-size: 24px;
      color: #323335;
      margin-left: 20px;
      cursor: pointer;
    }

    img {
      width: 19px;
      height: 19px;
      margin-left: 10px;
    }
  }

  .tu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .img1,
    .img3 {
      width: 10px;
      height: 12px;
    }

    .img2 {
      width: 214px;
      height: 214px;
      border-radius: 50%;
    }
  }

  .name1,
  .name2 {
    font-size: 14px;
    color: #42454a;
    text-align: center;
    overflow: hidden;
    line-height: 20px;
    margin-top: 7px;
  }

  .price {
    font-size: 14px;
    color: #fd0d00;
    text-align: center;
    line-height: 15px;
    margin-top: 7px;
  }
  .el-carousel__button {
    background-color: #333;
  }
}
</style>
