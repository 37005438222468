<template>
  <div class="logo">
    <div class="login">
      <div class="left" @click="handleBackHome">
        <img src="../../assets/login/logo.gif" alt="" />
      </div>
      <div class="right">
        <div class="left">
          <div class="shangpin">{{ shang }}</div>
          <img @click.stop="ShowHidden = !ShowHidden" class="img1" src="../../assets/home/xiajiantou.png" />
          <div class="xuanze" @click.stop="" v-if="ShowHidden">
            <div class="yincang" v-for="(item, index) in xianshi" :key="index" @click="ConBtn(index)">
              {{ item }}
            </div>
          </div>
        </div>
        <div class="center">
          <input v-model="searchInput" type="text" :placeholder="$t('placeholder.Home_Search')" onkeyup="this.value=this.value.replace(/[, ]/g,'')" @keyup.enter.prevent="handleSearchClick" />
        </div>
        <div class="right" @click="handleSearchClick">{{ $t('button.Search') }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Search',
  props: ['msg'],
  props: ['searchInput'],
  data() {
    return {
      aaa: '',
      ShowHidden: false,
      xianshi: [this.$t('label.Goods'), this.$t('label.Store')],
      shang: this.$t('label.Goods'),
      searchInput: ''
    }
  },
  mounted() {
    document.addEventListener('click', this.HiddenClick)
  },
  methods: {
    HiddenClick() {
      this.ShowHidden = false
    },
    ConBtn(val) {
      this.ShowHidden = false
      this.shang = this.xianshi[val]
    },
    handleBackHome() {
      this.$router.push({
        name: 'Home'
      })
    },
    handleSearchClick() {
      if (this.shang == this.$t('label.Goods')) {
        // this.$router.push({
        //   path: '/search',
        //   query: {
        //     q: this.searchInput,
        //   },
        // });
        let sp_search = this.$router.resolve({
          path: '/search',
          query: {
            q: this.searchInput
          }
        })
        window.open(sp_search.href, '_blank')
      } else {
        // this.$router.push({
        //   path: '/search_store',
        //   query: {
        //     q: this.searchInput,
        //   },
        // });
        let dp_search = this.$router.resolve({
          path: '/search_store',
          query: {
            q: this.searchInput
          }
        })
        window.open(dp_search.href, '_blank')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.logo {
  width: 100%;
  min-width: 1200px;
  background: #fff;
  .login {
    width: 1200px;
    height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }
  .left {
    width: 158px;
    height: 44px;
    margin-left: 33px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    display: flex;
    height: 42px;
    line-height: 42px;
    border: 2px solid #6b8fff;
    border-right: none;
    .left {
      width: 95px;
      display: flex;
      align-items: center;
      color: #666;
      border-right: 2px solid #6b8fff;
      position: relative;
      cursor: pointer;
      font-size: 16px;

      img {
        width: 15px;
        height: 10px;
        margin-left: 20px;
      }
      .xuanze {
        width: 70px;
        background: #fff;
        position: absolute;
        left: -18px;
        top: 45px;
        z-index: 999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 5px;
      }
    }
    .center {
      width: 480px;
      input {
        border: 0;
        outline: none;
        width: 100%;
        padding-left: 10px;
      }
    }
    .right {
      color: #fffeff;
      background: #1953ff;
      width: 133px;
      display: flex;
      justify-content: center;
      border: none;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>
