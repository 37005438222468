import moment from 'moment'
import _format from './data_format'

/**
 * 格式化时间
 * @param {Date|string|number} time - 时间（支持 Date 对象、时间字符串、时间戳）
 * @param {string} format - 格式模板（默认 'YYYY-MM-DD HH:mm:ss'）
 * @returns {string} 格式化后的时间字符串
 */
export function format(time = new Date(), format = _format.datetime) {
  if (!time) return ''
  return moment(time).format(format)
}

/**
 * 增加时间
 * @param {Date|string|number} time - 基准时间
 * @param {number} value - 增减数值
 * @param {string} unit - 单位（如 'days', 'hours', 'minutes' 等）
 * @returns {Date} 计算后的时间对象
 */
export function add(time = new Date(), value, unit) {
  return moment(time).add(value, unit).toDate()
}

/**
 * 减少时间
 * @param {Date|string|number} time - 基准时间
 * @param {number} value - 增减数值
 * @param {string} unit - 单位（如 'days', 'hours', 'minutes' 等）
 * @returns {Date} 计算后的时间对象
 */
export function subtract(time = new Date(), value, unit) {
  return moment(time).subtract(value, unit).toDate()
}

/**
 * 计算时间差
 * @param {Date|string|number} time - 时间（支持 Date 对象、时间字符串、时间戳）
 * @param {string} format - 格式模板（默认 'YYYY-MM-DD HH:mm:ss'）
 * @returns {string} 格式化后的时间字符串, 1小时前
 */
export function fromNow(time, format = _format.datetime) {
  return moment(time, format).fromNow()
}

format.add = add
format.format = _format
format.subtract = subtract
format.fromNow = fromNow

export default format
