<template>
  <div>
    <div class="nav w-1920" @mouseleave="yincang">
      <div class="menu w-1200">
        <div
          class="moren text-nowrap"
          :class="[{ gaibian: index == TabIndex }]"
          v-for="(item, index) in menu"
          :key="index"
          @mouseover="selectedTabItem(index, $event)"
          @click="handleSearchClick(item.prodCatId, item.url, item.lb)"
        >
          {{ item.name }}
        </div>
        <div class="erji" v-if="List != null && List.length > 0" :style="{ position: position, display: display }">
          <div class="erji1" v-for="res in menu" :key="res.prodCatId" v-show="caidan">
            <div class="erji_menu">
              <div class="sanji">
                <ul>
                  <li v-for="(i, k) in List" :key="k" @mouseover="chufaerji(i)" @click="handleSearchClick(i.prodCatId, i.url, i.lb)" v-if="i.prodCatId != 211">
                    {{ i.name }}
                  </li>
                </ul>
                <!-- <ul v-show="sanji">
                  <li v-for="(item, index2) in Listl" :key="index2 + '1'"
                    @click="handleSearchClick(item.prodCatId,item.url,item.lb)">
                    {{ item.name }}
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post } from '@/utils/request'
export default {
  name: 'Menu',
  props: ['prodCatId'],
  data() {
    return {
      Listl: [],
      menu: [
        {
          name: this.$t('menu.Home'),
          datalist: [],
          url: '',
          lb: '1'
        },
        {
          name: this.$t('menu.Corporate_Journey'),
          lb: '8',
          datalist: [
            {
              name: this.$t('menu.Company_Profile'),
              url: 'https://www.jndytq.com/about.html',
              lb: '5'
            },
            {
              name: this.$t('menu.Corporate_Culture'),
              url: 'https://www.jndytq.com/companyculture.html',
              lb: '5'
            },
            {
              name: this.$t('menu.Corporate_News'),
              url: 'https://www.jndytq.com/deyangnew.html',
              lb: '5'
            },
            {
              name: this.$t('menu.Corporate_Certifications'),
              url: 'https://www.jndytq.com/qualifications.html',
              lb: '5'
            },
            {
              name: this.$t('menu.Subsidiary_Company'),
              url: 'https://www.jndytq.com/',
              lb: '5'
            }
          ]
        },
        {
          name: this.$t('menu.Shop'),
          lb: '2',
          datalist: []
        },
        {
          name: this.$t('menu.Certificate_Download'),
          datalist: [],
          lb: '5',
          url: 'https://biaowu.shop/#/helpCenter/22'
        },
        {
          name: this.$t('menu.Specifications_Download'),
          datalist: [],
          lb: '5',
          url: 'https://www.jndytq.com/biaozhun.html'
        },
        {
          name: this.$t('menu.Knowledge_Hub'),
          lb: '8',
          datalist: [
            {
              name: this.$t('menu.Standard_material_forum'),
              lb: '5',
              url: 'https://www.jndytq.com/Industry-dynamics.html'
            },
            {
              name: this.$t('menu.Professional_knowledge'),
              lb: '5',
              url: 'https://www.jndytq.com/Industry-dynamics.html'
            },
            {
              name: this.$t('menu.Industry_news'),
              lb: '1',
              url: 'industry'
            }
          ]
        },
        {
          name: this.$t('menu.Mall_Guide'),
          lb: '3',
          url: 'helpCenter1',
          datalist: [
            {
              lb: '3',
              name: this.$t('menu.Register_login'),
              url: 'helpCenter',
              prodCatId: 6
            },
            {
              name: this.$t('menu.Technical_consultation'),
              lb: '5',
              url: 'https://www.jndytq.com/contactus.html'
            }
          ]
        },
        {
          name: this.$t('menu.Help_Center'),
          lb: '5',
          url: 'https://www.jndytq.com/contactus.html',
          datalist: [
            {
              name: this.$t('menu.Contact_number') + ': 0531-84771678',
              lb: '7'
              //"url": "https://www.jndytq.com/contactus.html",
            },
            {
              name: this.$t('menu.Official_website_consultation'),
              lb: '5',
              url: 'https://www.jndytq.com/contactus.html'
            }
          ]
        }
      ],
      TabIndex: '',
      TabList: [],
      caidan: false,
      List: [],
      sanji: false,
      position: 'static',
      display: 'none'
    }
  },
  created() {
    this.getmenu()
    if (this.prodCatId == undefined) {
    } else {
      this.TabIndex = this.prodCatId
    }
  },
  methods: {
    handleSearchClick(name, url, lb) {
      if (lb == '1') {
        this.$router.push({
          path: '/' + url
        })
      } else if (lb == '2') {
        this.$router.push({
          path: '/search',
          query: {
            prodCatId: name
          }
        })
      } else if (lb == '3') {
        this.$router.push({
          name: url,
          params: {
            id: name
          }
        })
      } else if (lb == '5') {
        window.open(url, '_blank')
      } else if (lb == '6') {
        this.$message.warning({
          message: this.$t('message.under_development')
        })
      } else if (lb == '7') {
      } else if (lb == '8') {
      } else {
        this.$router.push({
          path: '/search',
          query: {
            prodCatId: name
          }
        })
      }
    },
    chufaerji(e) {
      this.Listl = e.datalist
      this.sanji = true
    },
    selectedTabItem(index, $event) {
      this.TabIndex = index
      this.caidan = true
      this.sanji = false
      this.List = this.menu[index].datalist
      this.position = 'absolute'
      this.display = 'block'
    },
    yincang() {
      this.caidan = false
      this.position = 'static'
      this.display = 'none'
      if (this.prodCatId == undefined) {
      } else {
        this.TabIndex = this.prodCatId
      }
    },
    getmenu() {
      get('f/api/frontcategory/getcategorylist?pageSize=10').then((res) => {
        this.menu[2].datalist = res.data.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
li {
  cursor: pointer;
}

.nav {
  width: 100%;
  height: 34px;
  border-bottom: 4px solid #ff902a;
  position: relative;
  background: #fff;

  .menu {
    width: 1200px;
    margin: 0 auto;
    display: flex;

    .moren {
      font-size: 16px;
      color: #282828;
      line-height: 38px;
      padding: 0 20px;
      cursor: pointer;
      margin-left: 10px;

      img {
        margin-right: 10px;
      }
    }

    .gaibian {
      background: #ff902a;
      color: #fff;
      font-size: 16px;
      line-height: 38px;

      img {
        margin-right: 10px;
      }
    }
  }

  .erji1 {
    width: 1234px;
    min-height: 400px;
    position: absolute;
    z-index: 998;
  }

  .erji {
    width: 1234px;
    min-height: 400px;
    //position: absolute;
    top: 34px;
    z-index: 999;
    display: none;

    .erji_menu {
      display: flex;
      width: 1234px;
      min-height: 400px;
      margin: 0 auto;
      background: rgba($color: #333, $alpha: 0.2);
      color: #fff;
      opacity: 0.6;
      filter: alpha(opacity=60);
      padding-bottom: 20px;

      .sanji {
        display: flex;

        ul {
          li {
            list-style: none;
            margin-top: 20px;
            display: block;
          }

          li:hover {
            border-bottom: 1px solid #fff;
          }
        }
      }
    }
  }
}
</style>
